<template>
  <header>
    <div class="nav">
      <router-link to="/" @click.native="linkRun">
        <img class="logoImg" src="../assets/img/header/baiwu-logo.png" alt="" />
        <img
          class="logoImg"
          src="../assets/img/header/mobile/baiwu-logo.png"
          alt=""
        />
      </router-link>
      <img
        src="../assets/img/header/mobile/header-icon.png"
        @click="showMobile($event)"
        alt=""
        class="nav_img"
      />
      <div class="mobile-ul">
        <ul>
          <li class="click-li-no">
            <router-link to="/" @click.native="linkRun">
              <p>首页</p>
              <span>></span>
            </router-link>
          </li>
          <li class="click-li" @click="clickLi($event)">
            <div>
              <p>产品中心</p>
              <span>></span>
            </div>
            <ul class="ul-one">
              <li v-for="(item, i) in p2Data" :key="i" @click.stop.prevent>
                <div @click="clickLiTwo($event)">
                  <p v-text="item.imgSpan"></p>
                  <span>></span>
                </div>
                <ul class="ul-two ul-plus">
                  <li v-for="(item2, i2) in item.content" :key="i2">
                    <router-link
                      :to="item2.link"
                      v-text="item2.span"
                      @click.native="linkRun"
                    ></router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="click-li" @click="clickLi($event)">
            <div>
              <p>解决方案</p>
              <span>></span>
            </div>
            <ul class="ul-one">
              <li v-for="(item, i) in p3Data" :key="i" @click.stop.prevent>
                <div @click="clickLiTwo($event)">
                  <p v-text="item.imgSpan"></p>
                  <span>></span>
                </div>
                <ul class="ul-two ul-plus">
                  <li v-for="(item2, i2) in item.content" :key="i2">
                    <router-link
                      :to="item2.link"
                      v-text="item2.span"
                      @click.native="linkRun"
                    ></router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="click-li-no">
            <a href="news/news.html">
              <p>新闻动态</p>
              <span>></span>
            </a>
          </li>
          <li class="click-li-no">
            <router-link to="/join" @click.native="linkRun">
              <p>诚聘英才</p>
              <span>></span>
            </router-link>
          </li>
          <li class="click-li" @click="clickLi($event)">
            <div>
              <p>关于我们</p>
              <span>></span>
            </div>
            <ul class="ul-one ul-plus">
              <li>
                <router-link to="/about#part1" @click.native="linkRun2"
                  >企业介绍</router-link
                >
              </li>
              <li>
                <router-link to="/about#part2" @click.native="linkRun2"
                  >企业文化</router-link
                >
              </li>
              <li>
                <router-link to="/about#part3" @click.native="linkRun2"
                  >企业发展历程</router-link
                >
              </li>
              <li>
                <router-link to="/about#part4" @click.native="linkRun2"
                  >荣誉资质</router-link
                >
              </li>
              <li>
                <router-link to="/about#part5" @click.native="linkRun2"
                  >社会责任</router-link
                >
              </li>
              <li>
                <router-link to="/about#part6" @click.native="linkRun2"
                  >联系我们</router-link
                >
              </li>
              <li>
                <router-link to="/honest" @click.native="linkRun"
                  >廉洁合规</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <ul class="nav-ul">
        <li>
          <router-link to="/">首页</router-link>
          <img src="../assets/img/header/arrow.png" alt="" />
        </li>
        <li>
          <p>产品中心</p>
          <img src="../assets/img/header/arrow.png" alt="" />
          <ul class="meau_ul">
            <li>
              <router-link to="/voice">95语音</router-link>
            </li>
            <li>
              <router-link to="/messageServe">短信服务</router-link>
            </li>
            <li>
              <router-link to="/internationalSms">国际短信</router-link>
            </li>
            <li>
              <router-link to="/5GMessage">5G消息</router-link>
            </li>
            <li>
              <router-link to="/fuseMessage">融合通信平台</router-link>
            </li>
            <li class="li_bold">
              <router-link to="/navigation">查看全部产品</router-link>
            </li>
          </ul>
        </li>
        <li>
          <p>解决方案</p>
          <img src="../assets/img/header/arrow.png" alt="" />
          <ul class="meau_ul">
            <li>
              <router-link to="/O2O">O2O解决方案</router-link>
            </li>
            <li>
              <router-link to="/ecommerce">电商解决方案</router-link>
            </li>
            <li>
              <router-link to="/transportation">交通运输解决方案</router-link>
            </li>
            <li>
              <router-link to="/finance">金融解决方案</router-link>
            </li>
            <li>
              <router-link to="/AI">人工智能解决方案</router-link>
            </li>
            <li>
              <router-link to="/mobileInternet">移动互联网解决方案</router-link>
            </li>
            <li>
              <router-link to="/governmentAndEnterprise"
                >政企解决方案</router-link
              >
            </li>
            <li>
              <router-link to="/smallAndMediumsizedEnterprises"
                >中小企业通信解决方案</router-link
              >
            </li>
          </ul>
        </li>
        <li>
          <a href="news/news.html">新闻动态</a>
          <img src="../assets/img/header/arrow.png" alt="" />
        </li>
        <li>
          <router-link to="/join">诚聘英才</router-link>
          <img src="../assets/img/header/arrow.png" alt="" />
        </li>
        <li>
          <p>关于我们</p>
          <img src="../assets/img/header/arrow.png" alt="" />
          <ul class="meau_ul">
            <li>
              <router-link to="/about#part1" @click.native="linkRun2"
                >企业介绍</router-link
              >
            </li>
            <li>
              <router-link to="/about#part2" @click.native="linkRun2"
                >企业文化</router-link
              >
            </li>
            <li>
              <router-link to="/about#part3" @click.native="linkRun2"
                >企业发展历程</router-link
              >
            </li>
            <li>
              <router-link to="/about#part4" @click.native="linkRun2"
                >荣誉资质</router-link
              >
            </li>
            <li>
              <router-link to="/about#part5" @click.native="linkRun2"
                >社会责任</router-link
              >
            </li>
            <li>
              <router-link to="/about#part6" @click.native="linkRun2"
                >联系我们</router-link
              >
            </li>
            <li>
              <router-link to="/honest">廉洁合规</router-link>
            </li>
            <!-- <li>
              <router-link to="/invite" @click.native="linkRun"
                >招标采购</router-link
              >
            </li> -->
          </ul>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import Utils from '../assets/js/util.js';
export default {
  data() {
    return {
      // p2初始化
      p2Data: [
        {
          imgSpan: '消息云',
          content: [
            {
              span: '5G消息',
              link: '/5GMessage',
            },
            {
              span: '会员短信',
              link: '/memberSMS',
            },
            {
              span: '视频短信',
              link: '/videoSMS',
            },
            {
              span: '通知短信',
              link: '/noticeSMS',
            },
            {
              span: '智媒短信',
              link: '/wisdomMedia',
            },
          ],
        },
        {
          imgSpan: '语音',
          content: [
            {
              span: '电话名片',
              link: '/telephoneCard',
            },
            {
              span: '固话外呼平台',
              link: '/fixedCall',
            },
            {
              span: '呼叫中心平台',
              link: '/callPlatform',
            },
            {
              span: '隐私通话',
              link: '/hideConnect',
            },
            {
              span: '语音验证码',
              link: '/voiceCode',
            },
          ],
        },
        {
          imgSpan: '国际通讯',
          content: [
            {
              span: '国际短信',
              link: '/internationalSms',
            },
            {
              span: '国际验证码短信',
              link: '/internationalCode',
            },
            {
              span: '国际营销短信',
              link: '/internationalMarketingSms',
            },
          ],
        },
        {
          imgSpan: '私有云',
          content: [
            {
              span: '5G消息平台',
              link: '/5GMessagePlatform',
            },
            {
              span: '短信平台',
              link: '/message',
            },
            {
              span: '呼叫中心平台',
              link: '/callPlatform',
            },
            {
              span: '融合通信平台',
              link: '/fuseMessage',
            },
          ],
        },
        {
          imgSpan: '95服务',
          content: [
            {
              span: '95码号服务',
              link: '/serve95',
            },
            {
              span: '95语音',
              link: '/voice',
            },
            {
              span: '95 5G消息',
              link: '/message955G',
            },
          ],
        },
        {
          imgSpan: '一键登录',
          content: [
            {
              span: '一键登录',
              link: '/oneclickLogin',
            },
          ],
        },
      ],
      // p3初始化
      p3Data: [
        {
          imgSpan: '行业解决方案',
          content: [
            {
              span: '电商解决方案',
              link: '/ecommerce',
            },
            {
              span: 'O2O解决方案',
              link: '/O2O',
            },
            {
              span: '交通运输解决方案',
              link: '/transportation',
            },
            {
              span: '金融解决方案',
              link: '/finance',
            },
            {
              span: '移动互联网解决方案',
              link: '/mobileInternet',
            },
            {
              span: '政企解决方案',
              link: '/governmentAndEnterprise',
            },
          ],
        },
        {
          imgSpan: '专项解决方案',
          content: [
            {
              span: '人工智能解决方案',
              link: '/AI',
            },
            {
              span: '中小企业解决方案',
              link: '/smallAndMediumsizedEnterprises',
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      $(window).resize(function () {
        if ($(window).width() <= 1200 && $(window).width() > 768) {
          $('header').width($('html').width());
          $('.nav_img').attr(
            'src',
            require('../assets/img/header/mobile/header-icon.png')
          );
        }
        if ($(window).width() > 1200 || $(window).width() <= 768) {
          $('header').width('100%');
        }
        if ($(window).width() > 768) {
          $('.mobile-ul').css('display', 'none');
        }
      });
    },
    showMobile(e) {
      if ($('.mobile-ul').css('display') == 'none') {
        $('.nav_img').attr(
          'src',
          require('../assets/img/header/mobile/header-icon-over.png')
        );
        $('.mobile-ul').stop().slideDown();
      } else {
        $('.nav_img').attr(
          'src',
          require('../assets/img/header/mobile/header-icon.png')
        );
        $('.mobile-ul').stop().slideUp();
      }
    },
    clickLi(e) {
      if ($(e.currentTarget).find('.ul-one').is('.active')) {
        $('.ul-one').removeClass('active');
      } else {
        $('.ul-one').removeClass('active');
        $(e.currentTarget).find('.ul-one').addClass('active');
      }
    },
    clickLiTwo(e) {
      $('.ul-two').stop().slideUp();
      if ($(e.currentTarget).next('.ul-two').css('display') == 'none') {
        $(e.currentTarget).next('.ul-two').stop().slideDown();
      } else {
        $(e.currentTarget).next('.ul-two').stop().slideUp();
      }
    },
    // 跳转页面
    linkRun() {
      $('.mobile-ul').stop().slideUp();
      $('.nav_img').attr(
        'src',
        require('../assets/img/header/mobile/header-icon.png')
      );
    },
    linkRun2() {
      Utils.$emit('demo', 'msg');
      $('.nav_img').attr(
        'src',
        require('../assets/img/header/mobile/header-icon.png')
      );
      $('.mobile-ul').stop().slideUp();
    },
  },
};
</script>

<style lang="less" scoped>
header {
  box-sizing: border-box;
  width: 100%;
  height: 79px;
  background-color: #ffffff;
  border-bottom: 1px solid rgb(250, 250, 250);
  z-index: 999;
  position: relative;
  font-size: 16px;
  position: fixed;
  top: 0;
  left: 0;
  .nav {
    width: 1110px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logoImg {
      width: 94px;
      height: auto;
    }
    .logoImg:last-child {
      display: none;
    }
    > img {
      display: none;
    }
    .nav-ul {
      box-sizing: border-box;
      width: 898px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > li {
        height: 100%;
        font-size: 16px;
        color: #333333;
        display: flex;
        flex-direction: row;
        align-items: center;
        letter-spacing: 0.5px;
        position: relative;
        cursor: pointer;
        p {
          margin-right: 5px;
        }
        img {
          width: 11px;
          height: 8px;
        }
        .meau_ul {
          position: absolute;
          display: block;
          width: 272px;
          top: 100%;
          left: -13px;
          padding: 30px;
          background: rgba(255, 255, 255, 0.95);
          border-radius: 4px;
          z-index: 999;
          display: none;
          li {
            margin-bottom: 13px;
            cursor: pointer;
            a {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
            }
          }
          .li_bold {
            a {
              color: #222222;
              font-weight: bold;
            }
          }
          li:hover {
            a {
              font-weight: bold;
              font-style: italic;
              color: #005cb9;
            }
          }
          li:last-child {
            margin-bottom: 0px;
          }
        }
      }
      > li::after {
        content: '';
        position: absolute;
        border-bottom: 2px solid #005cb9;
        left: 51%;
        right: 51%;
        bottom: 0;
        transition: all 0.3s linear;
      }
      > li:hover {
        > a {
          color: #005cb9;
          font-weight: bold;
        }
        > p {
          color: #005cb9;
          font-weight: bold;
        }
        img {
          content: url('../assets/img/header/arrow-hover.png');
        }
        .meau_ul {
          display: block;
        }
      }
      > li:hover::after {
        left: 0;
        right: 0;
      }
    }
    .mobile-ul {
      display: none;
    }
  }
  .nav::after,
  .nav::before {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  header {
    .nav {
      width: 90%;
      .nav-ul {
        width: 80%;
        > li {
          .meau_ul {
            width: 220%;
          }
        }
      }
      .mobile-ul {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  header {
    height: 1.06rem;
    position: relative;
    .nav {
      width: calc(100% - 1.34rem);
      .logoImg:first-child {
        display: none;
      }
      .logoImg:last-child {
        display: block;
        height: 0.42rem;
        width: auto;
      }
      > img {
        display: block;
        width: 0.44rem;
        height: 0.44rem;
      }
      .nav-ul {
        display: none;
      }
      .mobile-ul {
        display: block;
        position: absolute;
        top: 100%;
        left: 0%;
        width: 100%;
        background: #ffffff;
        display: none;
        li {
          width: 100%;
          border-bottom: 1px solid #dddddd;
          > a,
          div {
            width: calc(100% - 1.34rem);
            height: 50px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
          .ul-one {
            width: 100%;
            background: #fafafa;
            overflow: hidden;
            transition: max-height 1s;
            max-height: 0px;
            li {
              border: 0px;
              background: transparent;
            }
          }
          .ul-one.active {
            max-height: 700px;
          }
          .ul-two {
            width: 100%;
            background: #fafafa;
            display: none;
            li {
              border: 0px;
              background: transparent;
            }
          }
          .ul-plus {
            a {
              width: calc(100% - 1.64rem);
            }
          }
        }
      }
    }
  }
}
</style>
