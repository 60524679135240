export const hunhe = {
  data() {
    return {
      scrollReveal: this.$scrollReveal(),
    };
  },
  mounted() {
    this.scrollReveal.reveal('.reveal-left', {
      // 动画的时长
      duration: 600,
      // 延迟时间
      delay: 300,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-right', {
      // 动画的时长
      duration: 600,
      // 延迟时间
      delay: 300,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'right',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-bottom', {
      // 动画的时长
      duration: 600,
      // 延迟时间
      delay: 200,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'bottom',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-bottom2', {
      // 动画的时长
      duration: 600,
      // 延迟时间
      delay: 600,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'bottom',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-bottom3', {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 1000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'bottom',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-bottom4', {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 2000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'bottom',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-null', {
      // 动画的时长
      duration: 500,
      // 延迟时间
      delay: 200,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '0px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-null2', {
      // 动画的时长
      duration: 500,
      // 延迟时间
      delay: 700,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '0px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-null3', {
      // 动画的时长
      duration: 500,
      // 延迟时间
      delay: 1200,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '0px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-null4', {
      // 动画的时长
      duration: 500,
      // 延迟时间
      delay: 1700,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '0px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-null5', {
      // 动画的时长
      duration: 500,
      // 延迟时间
      delay: 2200,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '0px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-left2', {
      // 动画的时长
      duration: 300,
      // 延迟时间
      delay: 600,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '100px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-left3', {
      // 动画的时长
      duration: 300,
      // 延迟时间
      delay: 900,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-left4', {
      // 动画的时长
      duration: 300,
      // 延迟时间
      delay: 1200,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '300px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-left5', {
      // 动画的时长
      duration: 300,
      // 延迟时间
      delay: 1500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'left',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '400px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
    this.scrollReveal.reveal('.reveal-right2', {
      // 动画的时长
      duration: 600,
      // 延迟时间
      delay: 1800,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'right',
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0.001,
      easing: 'linear',
      scale: 0.9,
    });
  },
};
